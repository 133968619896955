<template>
  <div class="report-container">
    <div class="report-title-box">
      会员购买记录
      <span class="close-style" @click="goBackHome">返 回</span>
    </div>
    <div class="divider"></div>
    <div class="report-content">
      <div v-if="total == 0" style="margin-top: 150px">
        <empty name="暂无数据"></empty>
      </div>
      <div class="report-box" v-show="total != 0">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-class-name="table_header"
          height="645px"
        >
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <!-- <el-table-column
            prop="recordId"
            label="订单编号"
            :show-overflow-tooltip="true"
          ></el-table-column> -->
          <el-table-column
            prop="memberName"
            label="会员类型"
            align="center"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="createDate"
            label="会员开通时间"
            align="center"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="expireDate"
            label="会员到期时间"
            align="center"
            width="160"
          ></el-table-column>
          <el-table-column prop="" label="支付金额（元）">
            <template slot-scope="scope"
              >￥{{ calcNum(scope.row.renewalAmount) }}</template
            >
          </el-table-column>
          <el-table-column
            prop="renewalDate"
            label="支付时间"
            width="160"
          ></el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <pagination
          ref="pagination"
          :total="total"
          @change="paginChange"
          v-show="total != 0"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
export default {
  components: {
    Pagination,
    Empty,
  },
  data() {
    return {
      total: 0,
      tableData: [],
      params: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.buyMemberHistory();
  },
  methods: {
    // 获取列表
    buyMemberHistory() {
      this.$api.personalCenter
        .buyMemberHistory(this.params)
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    goBackHome() {
      this.$router.push("/user/user-home");
    },
    // 保留两位小数方法
    calcNum(val) {
      return Number(val).toFixed(2);
    },
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      this.buyMemberHistory();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
